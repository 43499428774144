@import '../../styles/helpers/index.scss';

.header {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 100;

  transition: box-shadow 0.3s ease;

  &.shadow {
    box-shadow: 0px 5px 15px 0px rgba(map-get($colors, 'menu'), 0.5);
  }

  &__headline {
    padding: 20px 0;
    border-bottom: 1px solid map-get($colors, 'red');

    .container {
      @include space-between;
    }

    a {
      text-decoration: none;
      text-transform: uppercase;
      line-height: 100%;
    }

    &--logo {
      height: 50px;
      img {
        height: inherit;
      }
      @media screen and (max-width: $tablet-lg) {
        height: 30px;
      }
    }

    &--links {
      @include align-center;
    }

    &--phone {
      @include font-weight-600;
      font-size: 24px;
      color: #070707;
      &::before {
        content: '\e806';
        font-family: 'fontello';
        padding-right: 15px;
        color: map-get($colors, 'red');
        font-size: 20px;
        @media screen and (max-width: $tablet-lg) {
          font-size: 14px;
          padding-right: 10px;
        }
      }

      @media screen and (max-width: $tablet-lg) {
        font-size: 16px;
      }
      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    &--book {
      @include font-weight-700;
      margin-left: 30px;
      font-size: 14px;
      padding: 14px 20px;
      border-radius: 30px;
      background-color: map-get($colors, 'red');
      color: #fff;
      transition: box-shadow 0.3s ease;
      @media screen and (max-width: $tablet-lg) {
        padding: 8px 12px;
        margin-left: 0;
      }
      &:hover {
        box-shadow: 0 0 15px 0 rgba(map-get($colors, 'red'), 0.6);
      }
    }
  }

  &__navigation {
    @media screen and (max-width: $tablet-sm) {
      padding: 15px 0;
    }

    .container {
      @media screen and (max-width: $tablet-sm) {
        @include space-between;
      }
    }

    &--burger {
      display: none;
      width: 30px;

      span {
        display: block;
        border-bottom: 2px solid map-get($colors, 'heading');
        &:nth-of-type(2) {
          margin: 8px 0;
        }
      }
      @media screen and (max-width: $tablet-sm) {
        display: block;
      }
    }

    &--phone {
      display: none;

      @media screen and (max-width: $tablet-sm) {
        @include align-center;
      }

      a {
        display: block;
        @include font-weight-700;
        color: map-get($colors, 'heading');
        text-decoration: none;
        font-size: 18px;
        &::before {
          content: '\e806';
          font-family: 'fontello';
          padding-right: 15px;
          color: map-get($colors, 'red');
          font-size: 20px;
        }
      }
    }

    &--close {
      display: none;
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 32px;
      width: 40px;
      height: 40px;
      text-align: center;
      @media screen and (max-width: $tablet-sm) {
        display: block;
      }
    }

    &--menu_wrapper {
      display: flex;
      justify-content: center;
      position: relative;
      @media screen and (max-width: $tablet-sm) {
        position: absolute;
        top: -86px;
        left: -100%;
        z-index: 100;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        transition: left 0.3s ease;
        &.open {
          left: 0;
        }
      }
    }
  }
}

// Fist Level Menu
.menu {
  @include align-center;

  @media screen and (max-width: $tablet-sm) {
    flex-direction: column;
  }

  &__first-level-item {
    position: relative;
    @media screen and (max-width: $tablet-sm) {
      width: 100%;
    }
    &__header {
      @include font-weight-600;
      display: block;
      padding: 16px 15px;
      text-transform: uppercase;
      font-size: 15px;
      line-height: 100%;
      color: map-get($colors, 'menu');
      cursor: pointer;

      @media screen and (max-width: $tablet-sm) {
        width: 100%;
      }

      @at-root {
        span#{&} {
          &::after {
            content: '\e809';
            font-family: 'fontello';
            padding-left: 10px;
          }
        }
      }
    }

    &:hover {
      & > span,
      & > a {
        background-color: map-get($colors, 'red');
        color: #fff;
      }
      & > .menu-second-level {
        display: block;
      }
    }
  }
}

// Second Level Menu
.menu-second-level {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px dashed map-get($colors, 'red');

  @media screen and (max-width: $tablet-sm) {
    position: relative;
    border: none;
    background: transparent;
    padding: 0 30px;
  }

  &__item {
    position: relative;
    &--header {
      @include font-weight-600;
      display: block;
      padding: 16px 15px;
      text-transform: uppercase;
      font-size: 15px;
      line-height: 100%;
      color: map-get($colors, 'menu');
      cursor: pointer;
      white-space: nowrap;

      @at-root {
        span#{&} {
          &::after {
            content: '\f105';
            font-family: 'fontello';
            padding-left: 10px;
            @media screen and (max-width: $tablet-sm) {
              content: '\e809';
            }
          }
        }
      }
    }

    &:hover {
      & > span,
      & > a {
        background-color: map-get($colors, 'red');
        color: #fff;
        @media screen and (max-width: $tablet-sm) {
          background-color: transparent;
          color: map-get($colors, 'menu');
        }
      }
      & > .menu-third-level {
        display: block;
      }
    }
  }
}

// Third Level Menu
.menu-third-level {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #fff;
  border: 1px dashed map-get($colors, 'red');

  @media screen and (max-width: $tablet-sm) {
    position: relative;
    left: 0;
    border: none;
    padding: 0 30px;
  }

  &__item {
    &--header {
      @include font-weight-600;
      display: block;
      padding: 16px 15px;
      text-transform: uppercase;
      font-size: 15px;
      line-height: 100%;
      color: map-get($colors, 'menu');
      cursor: pointer;
      white-space: nowrap;
    }

    &:hover {
      & > span,
      & > a {
        background-color: map-get($colors, 'red');
        color: #fff;
        @media screen and (max-width: $tablet-sm) {
          background-color: transparent;
          color: map-get($colors, 'menu');
        }
      }
    }
  }
}
