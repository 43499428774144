@import '../../styles/helpers/index.scss';

.areas {
  margin: 140px 0 0;
  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .container {
    padding: 30px;
    background-image: url('../../static/images/backgrounds/areas.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 6px;
    z-index: 1;
    display: flex;
    align-items: center;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      align-items: start;
      border-radius: 0;
    }

    & > div {
      position: relative;
      // max-width: 50%;
      // width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      width: 100%;
      height: 100%;
      border: 1px dashed map-get($colors, 'red');
      border-radius: 6px;
      z-index: -1;
      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }
  }

  .section-title {
    max-width: 400px;
    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
    }
  }

  &-list {
    columns: 4;
    @media screen and (max-width: $tablet-sm) {
      columns: 2;
      margin: 60px 0 0;
    }
    li {
      position: relative;
      a {
        color: #fff;
        &:hover {
          text-decoration: underline !important;
        }
      }
      font-size: 17px;
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
      @include align-center;
      &::before {
        content: '\e807';
        font-family: 'fontello';
        font-size: 12px;
        padding-right: 15px;
        color: map-get($colors, 'red');
      }
    }
  }

  .red-label {
    position: absolute;
    bottom: -45px;
    right: -10px;
    max-width: 100px;
    animation: 4s linear 0s normal none infinite running rot;
    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }
}

.area-content {
  margin: 70px 0 0;
  color: map-get($colors, 'heading');
  font-size: 18px;
  line-height: 150%;

  &__map {
    margin: 70px 0 0;
    iframe {
      width: 100%;
      height: 600px;
      vertical-align: top;
    }
  }
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
