@import './helpers/reset.scss';
@import './helpers/variables.scss';
@import './helpers/mixins.scss';
@import './typography.scss';

body {
  font: normal 400 16px/1.24 'Montserrat Regular', sans-serif;
  height: inherit;
  background-color: #fff;
  color: #310003;
  overflow: auto;
  overflow-x: hidden;
}

#root {
  height: inherit;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: inherit;
  }

  main {
    flex: 1 0 auto;
    padding-top: 138px;
    @media screen and (max-width: $tablet-lg) {
      padding-top: 124px;
    }
    @media screen and (max-width: $tablet-sm) {
      padding-top: 116px;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  textarea {
    resize: none;
  }
}

.container {
  position: relative;
  max-width: 1240px;
  width: 100%;
  height: inherit;
  margin: 0 auto;
  padding: 0;
  @media screen and (max-width: $desktop-sm) {
    max-width: 100%;
    padding: 0 15px;
  }
  @media screen and (max-width: $tablet-sm) {
    max-width: 100%;
    padding: 0 15px;
  }
}

.red-button {
  display: inline-block;
  padding: 14px 20px;
  background-color: map-get($colors, 'red');
  text-transform: uppercase;
  color: #fff;
  font-size: 15px;
  line-height: 110%;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 0 15px 0 rgba(map-get($colors, 'red'), 0.6);
  }

  &.inverse {
    background-color: #fff;
    border: 1px solid map-get($colors, 'red');
    color: map-get($colors, 'red');
    @include font-weight-700;
    &:hover {
      background-color: map-get($colors, 'red');
      color: #fff;
      opacity: 1;
    }
  }
}

.image-with-border {
  position: relative;
  img {
    border-radius: 6px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100%;
    height: 100%;
    border: 1px dashed map-get($colors, 'red');
    border-radius: 6px;
  }
}

.slick-slider {
  .slick-next,
  .slick-prev {
    width: 60px;
    height: 60px;
    background: #fff;
    border: 1px dashed map-get($colors, 'red');
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: -100px;
    cursor: pointer;
    &::before {
      content: '\f105';
      font-family: 'fontello';
      color: map-get($colors, 'red');
    }
    @media screen and (max-width: 768px) {
      top: -30px;
      width: 45px;
      height: 45px;
    }
  }
  .slick-next {
    right: 20px;
    &::before {
      content: '\f178';
    }
  }
  .slick-prev {
    right: 100px;
    left: unset;
    &::before {
      content: '\f177';
    }
    @media screen and (max-width: 768px) {
      right: 80px;
    }
  }
}

.data-protection {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 20px;
    height: 20px;
  }

  p {
    margin: 0;
    font-size: 14px;
  }
}
