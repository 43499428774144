@import '../../styles/helpers/index.scss';

.get-in-touch {
  margin: 140px 0 0;
  padding: 100px 0;

  background-image: url('../../static/images/backgrounds/get-in-touch.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  color: #fff;
  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .section-title {
    @media screen and (min-width: $tablet-sm) {
      h2 {
        font-size: 56px;
      }
    }
  }

  p {
    margin: 40px 0 0;
    font-size: 17px;
    line-height: 150%;
  }

  form {
    margin: 45px 0 0;
    width: 100%;
    & > div {
      @include grid;
    }

    input:not([type='submit']),
    textarea {
      width: 100%;
      background: rgba(#fff, 0.85);
      border: none;
      border-radius: 6px;
      padding: 10px 30px;
      outline: none;
      font-family: 'Montserrat Regular', sans-serif;
      &::placeholder {
        color: inherit;
        font-family: inherit;
      }
    }

    textarea {
      height: 220px;
    }

    input:not([type='submit']) {
      height: 60px;

      &:not(:last-of-type) {
        margin: 0 0 20px;
      }
    }

    input[type='submit'] {
      margin: 40px 0 0;
    }
  }

  .data-protection {
    margin: 15px 0 0;

    svg {
      fill: #fff;
    }
  }
}

#contact-page .get-in-touch {
  margin: 0;
  padding: 90px 100px 90px 40px;

  @media screen and (max-width: $tablet-sm) {
    padding: 40px 20px;
  }

  form > div:first-of-type {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
  }
}
