@import '../../styles/helpers/index.scss';

.mobile-cta-buttons {
  display: none;

  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;

  background: #fff;
  box-shadow: 0px 0px 10px 3px rgb(7 7 7 / 50%);

  a {
    @include font-weight-700;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    width: 100%;

    &:first-of-type {
      color: map-get($colors, 'red');
      &::before {
        content: '\e806';
        font-family: 'fontello';
        padding-right: 15px;
        color: #f00100;
        font-size: 20px;
      }
    }

    &:last-of-type {
      background-color: map-get($colors, 'red');
      color: #fff;
    }
  }

  @media screen and (max-width: $tablet-sm) {
    display: flex;
  }
}

#tidio-chat-iframe {
  bottom: 60px !important;
}
