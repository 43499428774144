@import '../../styles/helpers/index.scss';

.superiority {
  position: relative;
  margin: 110px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 0;
  }

  .container {
    @include grid($columns: repeat(3, 1fr));

    @media screen and (max-width: $tablet-sm) {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 20px;
    }
  }

  .superior {
    @include align-center;
    flex-direction: column;
    justify-content: center;

    background: #fff;
    border: 1px dashed map-get($map: $colors, $key: 'red');
    border-radius: 6px;
    height: 260px;

    cursor: pointer;
    transition: all 0.3s ease;
    @media screen and (min-width: $tablet-sm) {
      &:hover {
        transform: scale(1.1);

        img {
          animation: pulse 2s;
        }
      }
    }

    &__image-wrapper {
      padding-bottom: 34px;
      img {
        height: 80px;
        width: auto;
      }
    }

    p {
      @include font-weight-900;
      color: map-get($map: $colors, $key: 'heading');
      margin-top: 30px;
      font-size: 18px;
      line-height: 130%;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

#home-page .superiority {
  &::before {
    content: '';
    background-color: #f7f3f1;
    position: absolute;
    top: -380%;
    left: -60%;

    width: 1620px;
    height: 1620px;
    border-radius: 50%;
    z-index: -1;

    @media screen and (max-width: $tablet-lg) {
      display: none;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
