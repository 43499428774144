@import '../../styles/helpers/index.scss';

.payment {
  margin: 140px 0 0;
  padding: 100px 0 40px;
  background-image: url('../../static/images/backgrounds/payment.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  color: #fff;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  h2 {
    @include font-weight-900;
    font-size: 56px;
    line-height: 120%;
    letter-spacing: 0.02em;
    @media screen and (max-width: $tablet-lg) {
      font-size: 36px;
    }
  }

  p {
    margin: 40px 0 0;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.02em;
    @media screen and (max-width: $tablet-lg) {
      font-size: 16px;
    }
  }

  &__link {
    @include align-center;
    margin: 50px 0 0;
    a {
      margin-right: 30px;
    }
    span {
      @include font-weight-700;
      font-size: 24px;
      line-height: 150%;
      color: #fff;
      @media screen and (max-width: $tablet-lg) {
        font-size: 18px;
      }
    }
  }
  &__payment-systems {
    margin: 10px 0 0;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: $tablet-lg) {
      margin-top: 30px;
      justify-content: flex-start;
    }
    & > div {
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }
}
