@import '../../styles/helpers/index.scss';

.post-content {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .container {
    display: flex;
  }

  .section-title {
    margin: 0 0 60px;
  }

  &__post {
    max-width: 810px;

    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
    }

    .image-with-border {
      margin: 0 0 60px;
      img {
        width: 100%;
      }
    }
    &--meta {
      display: flex;
      margin: 60px 0;

      .post-date,
      .post-categories {
        font-size: 15px;
        line-height: 155%;
      }
      .post-date {
        margin-right: 60px;
      }
      .post-categories {
        & > span {
          @include font-weight-700;
          &:not(:last-of-type) {
            &::after {
              content: ', ';
            }
          }
        }
      }
    }

    &--text {
      a {
        color: #310003;
        text-decoration: underline !important;

        &:hover {
          color: map-get($colors, 'red');
        }
      }
      p {
        font-size: 17px;
        line-height: 150%;
        margin-bottom: 15px;
      }
      h2 {
        margin: 60px 0 40px;
        font-size: 36px;
        line-height: 120%;

        @media screen and (max-width: $tablet-sm) {
          font-size: 24px;
        }
      }

      h3 {
        font-size: 22px;
        line-height: 120%;
        margin: 30px 0;
      }

      ul {
        margin: 20px 0 60px;
        list-style-type: circle;
      }
    }
  }

  &__latest {
    margin: 137px 0 0 40px;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }

    &--title {
      @include font-weight-900;
      font-size: 30px;
      line-height: 130%;
      margin: 0 0 45px;
    }

    ul {
      li {
        &::before {
          content: '\f105';
          font-family: 'fontello';
          color: map-get($colors, 'red');
          margin-right: 12px;
        }
        a {
          font-size: 17px;
          line-height: 150%;
          color: map-get($colors, 'heading');
          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }
  }
}
