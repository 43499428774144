@import '../../styles/helpers/index.scss';

.booking-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin: 70px 0 0;

  h2 {
    @include font-weight-900;
    font-size: 32px;
    color: map-get($colors, 'heading');
    text-transform: uppercase;
    margin: 0 0 30px;
  }

  form {
    max-width: 600px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
  }

  input:not([type='submit']),
  select,
  textarea {
    font-family: 'Montserrat Regular';
    margin: 0 0 15px;
    font-size: 16px;
    color: map-get($colors, 'heading');
    padding: 8px 10px;
    border: 1px dashed map-get($colors, 'heading');
    border-radius: 6px;
    outline: none;
    &::placeholder {
      color: inherit;
      font-family: inherit;
    }
  }

  textarea {
    height: 150px;
  }

  input[type='submit'] {
    max-width: 120px;
    margin: 30px auto 0;
    outline: none;
  }
}
