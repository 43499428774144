@import '../../styles/helpers/index.scss';

.call-to-action {
  margin: 140px 0 0;
  padding: 120px 0;
  background-image: url('../../static/images/backgrounds/call-to-action.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .container {
    @include align-center;
    justify-content: space-between;
    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .section-title {
    max-width: 800px;
    width: 100%;
    @media screen and (max-width: $tablet-sm) {
      margin: 0 0 60px;
    }
  }
}
