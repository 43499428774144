@import '../../styles/helpers/index.scss';

.contact-our-specialists {
  margin: 140px 0 0;
  @media screen and (max-width: 1024px) {
    margin: 70px 0 0;
  }

  .section-title {
    max-width: none;
  }

  &__form {
    margin: 45px 0 0;

    p {
      font-size: 17px;
      line-height: 150%;
      color: map-get($colors, 'text');
      max-width: 400px;
      margin-bottom: 40px;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      input {
        &:not([type='submit']) {
          border-radius: 6px;
          padding: 18px 20px;
          max-width: 600px;
          width: 100%;
          background: rgba(#fff, 0.8);
          border: 1px solid map-get($colors, 'border');
          margin-bottom: 20px;
          &:nth-of-type(odd) {
            margin-right: 40px;
            @media screen and (max-width: 768px) {
              margin-right: 0;
            }
          }
          @media screen and (max-width: 768px) {
            max-width: 100%;
          }
        }
        &[type='submit'] {
          margin: 40px 0 0;
        }
      }
    }
  }

  .data-protection {
    margin: 15px 0 0;
  }
}
