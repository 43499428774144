@import '../../styles/helpers/index.scss';

.our-team {
  margin: 140px 0 0;
  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__grid {
    margin: 60px 0 0;
    @include grid(40px, 40px, 1fr, repeat(3, 1fr));

    @media screen and (max-width: $tablet-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    .image-with-border {
      @media screen and (max-width: $tablet-sm) {
        &::before {
          display: none;
        }
      }
      img {
        width: 100%;
      }
    }

    &--card {
      color: map-get($colors, 'heading');
      &__name {
        margin: 50px 0 20px;
        @include font-weight-900;
        font-size: 24px;
        line-height: 150%;
        @media screen and (max-width: $tablet-sm) {
          margin: 20px 0 10px;
          font-size: 14px;
        }
      }
      &__position {
        font-size: 17px;
        line-height: 155%;
        @media screen and (max-width: $tablet-sm) {
          font-size: 12px;
        }
      }
    }
  }
}
