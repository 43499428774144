@import '../../styles/helpers/index.scss';

.two-columns-grid {
  margin: 130px 0 0;
  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .section-title {
    max-width: 650px;
    margin: 0 0 60px;
    @media screen and (max-width: $tablet-sm) {
      max-width: 100%;
    }
  }

  &__item {
    @include grid;
  }

  .grid-card {
    border-radius: 6px;
    transition: all 0.3s ease;
    @media screen and (min-width: $tablet-sm) {
      &:hover {
        transform: scale(1.03);
      }
    }
    h4 {
      @include font-weight-900;
      color: map-get($colors, 'heading');
      font-size: 30px;
      line-height: 120%;
      margin: 40px 0;
    }
  }

  .image-with-border {
    img {
      height: 400px;
      width: 100%;
    }
  }
}

#home-page .two-columns-grid {
  position: relative;

  &::before,
  &::after {
    content: '';
    background-color: #f7f3f1;
    position: absolute;

    border-radius: 50%;
    z-index: -1;

    @media screen and (max-width: $tablet-lg) {
      display: none;
    }
  }
  &::before {
    top: 0;
    right: -30%;

    width: 920px;
    height: 920px;
  }
  &::after {
    width: 1450px;
    height: 1450px;
    bottom: -20%;
    left: -30%;
  }

  @media screen and (max-width: $tablet-sm) {
    .image-with-border img {
      height: auto;
      object-fit: cover;
    }
  }
}

#services-page .two-columns-grid {
  @media screen and (max-width: $tablet-sm) {
    .image-with-border img {
      height: auto;
      object-fit: cover;
    }
  }
}

#blog-page .two-columns-grid {
  margin: 80px 0 0;
  .grid-card {
    .image-with-border {
      img {
        width: 100%;
        height: 440px;
        aspect-ratio: 16 / 9;
        object-fit: cover;
      }
    }
  }
}
