@import '../../styles/helpers/index.scss';

.satisfaction-guaranteed {
  margin: 140px 0;

  @media screen and (max-width: $tablet-lg) {
    margin: 70px 0 0;
  }

  .section-title {
    margin-bottom: 60px;
    max-width: 650px;
    @media screen and (max-width: $tablet-lg) {
      max-width: unset;
    }
  }

  .links {
    @include grid(0, 30px, 1fr, repeat(3, 1fr));

    @media screen and (max-width: $tablet-lg) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (max-width: $tablet-sm) {
      grid-row-gap: 20px;
    }

    &__wrapper {
      text-decoration: none;
      background: #fff;
      border: 1px dashed map-get($colors, 'red');
      border-radius: 6px;
      padding: 45px 105px 30px;
      text-align: center;
      font-weight: 400;
      font-size: 24px;
      line-height: 110%;
      color: #070707;
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.1);
      }
      & > div:first-of-type {
        display: flex;
        flex-direction: column;

        img {
          margin-bottom: 20px;
          height: 64px;
        }
        div {
          margin-bottom: 30px;
          white-space: nowrap;
          & > i {
            color: map-get($colors, 'red');
            &:not(:last-of-type) {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
