@import '../../styles/helpers/index.scss';

.reviews {
  margin: 140px 0 0;
  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .section-title {
    max-width: 650px;
    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
    }
  }

  &__list {
    margin: 60px 0 0;
    width: 100%;

    &--card {
      background: #fff;
      border: 1px dashed map-get($colors, 'red');
      border-radius: 6px;
      padding: 40px 30px 80px;
      height: 500px;
      &:nth-of-type(odd) {
        margin: 0 20px;
      }
      &__header {
        @include space-between;
        align-items: center;
        margin: 0 0 40px;
      }
      &__logo {
        background-color: #f7f3f1;
        border: 1px dashed #c9bdbd;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        @include align-center;
        justify-content: center;
        img {
          height: 40px;
          width: auto;
        }
      }
      &__date {
        & > div {
          @include space-between;
          width: 100px;
        }
        i {
          color: map-get($colors, 'red');
          &::before {
            margin: 0;
          }
        }
        span {
          margin: 15px 0 0;
          font-size: 15px;
          line-height: 155%;
          text-align: right;
          color: map-get($colors, 'text');
          display: block;
        }
      }
      h5 {
        @include font-weight-700;
        font-size: 17px;
        line-height: 150%;
        text-transform: uppercase;
        color: map-get($colors, 'heading');
        margin: 0 0 40px;
      }
      p {
        font-size: 17px;
        line-height: 150%;
        // color: map-get($colors, 'text');
        max-height: 210px;
        overflow: scroll;
      }
    }
  }
}
