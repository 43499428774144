@import '../../styles/helpers/index.scss';

.hero-with-form {
  position: relative;

  padding: 60px 0 40px;

  background-image: var(--bg-image);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &::before {
    content: '';
    width: 100%;
    height: 100%;

    background-color: rgba(#000, 0.5);
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  @media screen and (max-width: $tablet-sm) {
    background-image: none;
    padding: 0 0 40px;
  }

  .container {
    @include space-between;
    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }
  }

  &__content {
    padding: 40px 0 0;
    max-width: 800px;
    width: 100%;
    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      padding: 0;
    }

    &--mobile_img {
      display: none;
      height: 200px;
      margin: 0 -15px;
      img {
        object-fit: cover;
        height: inherit;
        width: 100%;
      }
      @media screen and (max-width: $tablet-sm) {
        display: block;
      }
    }

    &--mobile_title {
      display: none;
      margin: 30px 0 0;
      color: rgb(201, 189, 189);
      background-color: #fff;
      border: 1px dashed #c9bdbd;
      border-radius: 20px;
      padding: 10px 120px 10px 20px;
      font-size: 15px;
      line-height: 100%;
      @media screen and (max-width: $tablet-sm) {
        display: inline-block;
      }
    }

    &--title {
      @include font-weight-900;
      font-size: 70px;
      line-height: 120%;
      @media screen and (max-width: $tablet-sm) {
        font-size: 28px;
        line-height: 1.24;
        overflow: hidden;
        margin: 30px 0 0;
        color: map-get($colors, 'heading');
      }
    }

    &--subtitle {
      @include font-weight-600;
      font-size: 48px;
      line-height: 120%;

      @media screen and (max-width: $tablet-sm) {
        color: map-get($colors, 'heading');
        margin: 5px 0 0;
        font-size: 18px;
      }
    }

    &--banners {
      @include grid(20px, 20px, repeat(2, 1fr));
      margin: 75px 0 0;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }

      .rating-banner {
        @include space-around;
        align-items: center;
        height: 100px;
        background: rgba(#fff, 0.7);
        border-radius: 6px;
        text-decoration: none;

        @media screen and (max-width: $tablet-sm) {
          background: rgba(#ddd, 0.5);
          box-shadow: 0 -0 10px 0 rgba(0, 0, 0, 0.75);
        }

        &__logo {
          max-width: 30%;
          width: 100%;
          text-align: center;
          img {
            margin: 0 auto;
          }
        }
        &__title {
          font-size: 17px;
          line-height: 110%;
          max-width: 50%;
          text-align: center;
          width: 100%;
          span {
            color: map-get($map: $colors, $key: 'heading');
          }
          strong {
            @include font-weight-700;
          }
        }
        &__stars {
          margin: 13px 0 0;
          color: map-get($map: $colors, $key: 'red');
          font-size: 20px;
          & > i {
            &:not(:last-of-type) {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
  &__feedback-form {
    background: rgba(#fff, 0.7);
    border-radius: 6px;
    max-width: 390px;
    width: 100%;
    margin-left: 40px;
    padding: 40px 35px;

    @media screen and (max-width: $tablet-sm) {
      max-width: 100%;
      margin-left: 0;
      margin-top: 30px;

      background: rgba(#ddd, 0.5);
      box-shadow: 0 -0 10px 0 rgba(0, 0, 0, 0.75);
      display: none;
    }

    h3 {
      @include font-weight-700;
      color: map-get($map: $colors, $key: 'heading');
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    input:not([type='submit']),
    textarea {
      padding: 10px 20px;
      margin-bottom: 16px;
      width: 100%;
      font-family: 'Montserrat Regular', sans-serif;
      font-size: 13px;
      line-height: 155%;
      background: #fff;
      border: 1px solid map-get($map: $colors, $key: 'border');
      color: map-get($map: $colors, $key: 'text');
      &::placeholder {
        color: inherit;
        font-family: inherit;
      }
    }

    textarea {
      height: 100px;
      @media screen and (max-width: $tablet-sm) {
        height: 150px;
      }
    }

    .red-button {
      margin: 30px 0 0;
    }
  }
}

#home-page {
  .hero-with-form::before {
    display: none !important;
  }
}
