h1 {
  // font-size: 76px;
  // line-height: 120%;
  @media screen and (max-width: 768px) {
    font-size: 48px;
  }
}

strong {
  font-family: 'Montserrat Bold', sans-serif;
}
