@import '../../styles/helpers/index.scss';

.footer {
  background-color: #f5f1ee;
  font-size: 13px;

  & > .container {
    padding: 90px 0;
    @include space-between;

    @media screen and (max-width: $tablet-lg) {
      flex-direction: column;
      padding: 90px 15px;
      align-items: center;
    }

    @media screen and (max-width: $tablet-sm) {
      margin: 45px auto;
    }

    & > div {
      width: 100%;
      @media screen and (max-width: $tablet-lg) {
        max-width: 500px;
        &:not(:last-of-type) {
          margin-bottom: 60px;
        }
      }
      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
        &:not(:last-of-type) {
          margin-bottom: 40px;
        }
      }
    }
  }

  h4 {
    @include font-weight-700;
    line-height: 150%;
    margin-bottom: 22px;
    text-transform: uppercase;
  }

  &__description {
    max-width: 330px;
    &--logo {
      height: 30px;
      img {
        height: inherit;
      }
    }

    &--text {
      margin: 45px 0 0;
      font-size: 13px;
      line-height: 150%;
    }

    .social-networks {
      margin-top: 100px;
      display: flex;
      @media screen and (max-width: $tablet-lg) {
        margin-top: 30px;
      }
      @media screen and (max-width: $tablet-sm) {
        margin-top: 30px;
      }
      a {
        @include align-center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #976f4f;
        @include scale;
        &:not(:first-of-type) {
          margin-left: 22px;
        }
      }
    }
  }

  &__navigation {
    max-width: 370px;
    @include grid($column-gap: 40px);
    &--block {
      li {
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
      a {
        color: #4d2a16;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }

  &__contacts {
    max-width: 250px;
    &--block {
      display: flex;
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
      i {
        margin-right: 10px;
        color: map-get($colors, 'red');
      }
      a {
        text-decoration: none;
        color: #310003;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }

  &__underline {
    user-select: none;
    padding: 30px 0;
    background-color: #fff;
    font-size: 12px;
    color: #976f4f;
    @media screen and (max-width: $tablet-lg) {
      text-align: center;
      padding: 30px 0 90px;
    }

    .container {
      @include space-between;
      @media screen and (max-width: $tablet-sm) {
        flex-direction: column;
      }
    }

    a {
      color: #976f4f;
      @media screen and (max-width: $tablet-sm) {
        margin-top: 20px;
      }
    }
  }
}
