@import '../../styles/helpers/index.scss';

.section-title {
  &__name {
    display: inline-block;
    background-color: #fff;
    border: 1px dashed #c9bdbd;
    border-radius: 20px;
    padding: 10px 120px 10px 20px;
    font-size: 15px;
    line-height: 100%;
    margin: 0 0 40px;
    @media screen and (max-width: $tablet-sm) {
      margin: 0 0 20px;
    }
  }
  &__heading {
    @include font-weight-900;
    font-size: 64px;
    line-height: 120%;
    @media screen and (max-width: $tablet-sm) {
      font-size: 32px;
    }
  }
}
