@import '../../styles/helpers/index.scss';

.we-services {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__content {
    &--description {
      margin: 40px 0 0;
      font-size: 17px;
      line-height: 150%;
      p {
        &:not(:last-of-type) {
          margin: 0 0 15px;
        }

        img {
          &.alignleft {
            float: left;
            margin-right: 15px;
          }

          &.alignright {
            float: right;
            margin-left: 15px;
          }

          @media screen and (max-width: $tablet-sm) {
            float: none !important;
            margin: 0 auto 15px !important;
          }
        }
      }
      h3,
      h2 {
        @include font-weight-700;
        margin: 30px 0 15px;
      }

      h2 {
        font-size: 36px;

        @media screen and (max-width: $tablet-sm) {
          font-size: 24px;
        }
      }

      h3 {
        font-size: 18px;
      }

      a {
        &:hover {
          text-decoration: underline !important;
        }
      }

      ul {
        margin: 40px 0 0;
        li {
          position: relative;
          &::before {
            content: '\f105';
            font-family: 'fontello';
            color: map-get($colors, 'red');
            font-size: 20px;
            margin-right: 15px;
          }

          &:not(:last-of-type) {
            margin: 0 0 15px;
          }
        }
      }
    }
  }
}
