@import '../../styles/helpers/index.scss';

.page-title {
  background-image: url('../../static/images/backgrounds/page-title.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 160px 0;
  position: relative;
  min-height: 400px;

  h1 {
    @include font-weight-900;
    max-width: 650px;
    font-size: 64px;
    line-height: 120%;
    color: #fff;
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
    @media screen and (max-width: 768px) {
      font-size: 48px;
    }
  }
}
