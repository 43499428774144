@import '../../styles/helpers/index.scss';

.categories {
  margin: 140px 0 0;
  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;

    & > div {
      padding: 14px 20px;
      border: 1px solid map-get($colors, 'red');
      border-radius: 6px;
      color: map-get($colors, 'red');
      text-transform: uppercase;
      @include font-weight-700;
      font-size: 15px;
      line-height: 110%;
      cursor: pointer;
      &.active {
        background-color: map-get($colors, 'red');
        color: #fff;
      }
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }
}
