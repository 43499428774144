@import '../../styles/helpers/index.scss';

.counter {
  margin: 140px 0 0;
  position: relative;

  .container {
    @include space-around;
    background-image: url('../../static/images/backgrounds/counter.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 60px 0 70px;
    border-radius: 6px;
    z-index: 0;
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      width: 100%;
      height: 100%;
      border: 1px dashed map-get($colors, 'red');
      border-radius: 6px;
      z-index: -1;
      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }
  }

  &-item {
    color: map-get($colors, 'heading');
    text-align: center;
    @media screen and (max-width: $tablet-sm) {
      &:not(:last-of-type) {
        margin: 0 0 30px;
      }
    }
    p {
      @include font-weight-700;
      font-size: 72px;
      line-height: 150%;
      @media screen and (max-width: $tablet-sm) {
        font-size: 48px;
      }
    }
    span {
      font-size: 24px;
      line-height: 120%;
      @media screen and (max-width: $tablet-sm) {
        font-size: 18px;
      }
    }
  }
}
