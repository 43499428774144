@import '../../styles/helpers/index.scss';

.accordion-item {
  margin: 0 0 30px;
  background: #fff;
  border: 1px dashed map-get($colors, 'red');
  border-radius: 6px;
  padding: 32px 40px;
  color: map-get($colors, 'text');
  @media screen and (max-width: $tablet-lg) {
    padding: 32px 20px;
  }
  .accordion-title {
    cursor: pointer;
    @include space-between;
    align-items: center;
    font-size: 24px;
    line-height: 150%;
    color: map-get($colors, 'heading');
    @media screen and (max-width: $tablet-lg) {
      font-size: 16px;
    }
    h3 {
      @include font-weight-700;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed map-get($colors, 'red');
      border-radius: 50%;
      width: 60px;
      height: 60px;
      text-align: center;
      @media screen and (max-width: $tablet-lg) {
        width: 30px;
        height: 30px;
      }
      img {
        width: 30px;
        fill: 'red';
        @media screen and (max-width: $tablet-lg) {
          width: 15px;
        }
      }

      &.active {
        background-color: #f7f3f1;
      }
    }
  }
  .accordion-content {
    margin-top: 30px;
    padding-left: 25px;
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    @media screen and (max-width: $tablet-lg) {
      font-size: 14px;
      padding-left: 0;
    }
  }
}
