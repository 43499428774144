@import '../../styles/helpers/index.scss';

.call-us {
  margin: 140px 0 0;
  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &.with-border {
    .container {
      padding: 120px 30px;
      background-image: url('../../static/images/backgrounds/call-us-wood.jpeg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 6px;
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 10px;
        width: 100%;
        height: 100%;
        border: 1px dashed map-get($colors, 'red');
        border-radius: 6px;
        z-index: -1;
        @media screen and (max-width: $tablet-sm) {
          display: none;
        }
      }
    }
    span,
    span > a {
      color: map-get($colors, 'heading');
    }
  }

  &.full-width {
    padding: 95px 0 85px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../static/images/backgrounds/call-us-full-width.png');
    span,
    a,
    .section-title h2 {
      color: #fff !important;
    }
  }

  .container {
    .section-title {
      max-width: 60%;
      margin: 0 0 60px;
      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
        text-align: center;
      }
    }
  }
  &__buttons {
    @include align-center;
    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }
    span {
      @include font-weight-900;
      display: block;
      margin: 0 0 0 40px;
      font-size: 24px;
      line-height: 200%;
      @media screen and (max-width: $tablet-sm) {
        margin: 15px 0 0;
      }
      & > a {
        color: map-get($colors, 'heading');
        cursor: pointer;
      }
    }
  }
}

#area-page {
  .call-us {
    margin: 0;
  }
}
