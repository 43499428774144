@import '../../styles/helpers/index.scss';

.portfolio {
  margin: 140px 0 0;
  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .section-title {
    margin: 0 0 60px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 60px;

    @media screen and (max-width: $tablet-sm) {
      grid-template-columns: repeat(1, 1fr);
    }

    & > div {
      &:nth-of-type(1) {
        grid-area: 1 / 1 / 2 / 2;
        @media screen and (max-width: $tablet-sm) {
          grid-area: auto;
        }
      }
      &:nth-of-type(2) {
        grid-area: 2 / 1 / 3 / 2;
        @media screen and (max-width: $tablet-sm) {
          grid-area: auto;
        }
      }
      &:nth-of-type(3) {
        grid-area: 1 / 2 / 3 / 3;
        @media screen and (max-width: $tablet-sm) {
          display: none;
        }
      }
      &:nth-of-type(4) {
        grid-area: 1 / 3 / 2 / 4;
        @media screen and (max-width: $tablet-sm) {
          grid-area: auto;
        }
      }
      &:nth-of-type(5) {
        grid-area: 2 / 3 / 3 / 4;
        @media screen and (max-width: $tablet-sm) {
          grid-area: auto;
        }
      }
    }

    &--card {
      max-width: 388px;
      width: 100%;

      &:not(:nth-of-type(3)) {
        & > div:first-of-type {
          height: 280px;
        }
      }
      & > div:first-of-type > div {
        border-radius: 6px;
        height: inherit !important;
      }

      &:nth-of-type(3) {
        & > div:first-of-type {
          height: 722px;
        }
      }

      &__photo {
        position: relative;
        box-shadow: 5px 5px 10px 0px rgba(#000, 0.85);
        border-radius: 6px;
        &::before {
          content: '';
          position: absolute;
          top: 10px;
          left: 10px;
          width: 100%;
          height: 100%;
          border: 1px dashed map-get($colors, 'red');
          border-radius: 6px;
          user-select: none;
        }
      }

      &__title {
        @include font-weight-900;
        font-size: 24px;
        line-height: 150%;
        margin: 30px 0 10px;
        color: map-get($colors, 'heading');
      }

      &__description {
        font-size: 17px;
        line-height: 150%;
        color: map-get($colors, 'heading');
      }
    }
  }
}
