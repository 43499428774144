@import '../../styles/helpers/index.scss';

.articles {
  margin: 140px 0 0;
  @media screen and (max-width: 1024px) {
    margin: 70px 0 0;
  }

  &__list {
    margin: 45px 0 0;
    &--card {
      max-width: 380px;
      width: 100%;
      .image-with-border {
        width: 100%;
        max-height: 380px;
        height: 100%;
        img {
          height: inherit;
          width: inherit;
        }
      }
      span {
        display: block;
        margin: 30px 0 25px;
        font-size: 15px;
        line-height: 155%;
        color: #c9bdbd;
      }

      h4 {
        @include font-weight-900;
        color: map-get($colors, 'heading');
        font-size: 18px;
        line-height: 150%;
        margin: 0 0 30px;
      }
    }
  }
}
