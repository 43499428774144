@import '../../styles/helpers/index.scss';

.who-we-are {
  margin: 190px 0 0;
  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .container {
    @include space-between;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }
  }

  &__content {
    max-width: 540px;
    width: 100%;
    @media screen and (max-width: $tablet-sm) {
      max-width: 100%;
    }
    h3 {
      @include font-weight-700;
      margin: 40px 0 30px;
      color: map-get($colors, 'red');
      font-size: 30px;
      line-height: 150%;
    }
    p {
      font-size: 17px;
      line-height: 150%;
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
    a {
      display: inline-block;
    }
    &--text {
      margin: 0 0 40px;
    }
  }
  .image-with-border {
    margin: 80px 0 0;
    max-height: 600px;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }

    img {
      max-height: 760px;
      height: 100%;
      // width: auto;
      width: 100%;
      object-fit: cover;
    }
  }
}
