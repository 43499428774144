@import '../../styles/helpers/index.scss';

.faq {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  &__list {
    margin: 60px 0 0;
  }
}

#faq-page .faq {
  margin: 80px 0 0;
  &__list {
    margin: 0;
  }
  .section-title {
    display: none;
  }
}
