@import '../../styles/helpers/index.scss';

.page-about {
  margin: 140px 0 0;
  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .section-title {
    max-width: 900px;
    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
    }
  }

  &__content {
    margin: 60px 0 0;
    @include grid;
    font-size: 17px;
    line-height: 150%;
    @media screen and (max-width: $tablet-sm) {
      font-size: 14px;
    }
  }
}

#contact-page .section-title {
  max-width: unset;
}
