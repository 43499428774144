@import '../../styles/helpers/index.scss';

.info-block {
  margin: 140px 0 0;
  padding: 95px 0;

  background-image: url('../../static/images/backgrounds/info-block.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .container {
    @include font-weight-700;
    color: #fff;
    text-align: center;
    font-size: 24px;
    line-height: 155%;

    a {
      color: #fff;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}
