@import '../../styles/helpers/index.scss';

.contact-us {
  @include grid;

  .content {
    padding-left: 100px;
    padding-top: 90px;
    padding-bottom: 90px;

    @media screen and (max-width: $tablet-sm) {
      padding: 60px 20px 0;
    }

    .section-title {
      margin-bottom: 45px;
    }

    & > p {
      font-size: 17px;
      line-height: 155%;
      margin: 0 0 60px;
    }
  }

  &__data {
    &--item {
      display: flex;
      font-size: 24px;
      line-height: 150%;
      &:not(:last-of-type) {
        margin: 0 0 40px;
      }
      @media screen and (max-width: $tablet-sm) {
        font-size: 16px;
      }
      p,
      a {
        @include font-weight-700;
        font-size: 30px;
        line-height: 155%;
        color: map-get($colors, 'heading');
        @media screen and (max-width: $tablet-sm) {
          font-size: 18px;
        }
      }
      i {
        @include align-center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border: 1px dashed map-get($colors, 'red');
        border-radius: 50%;
        color: map-get($colors, 'red');
        font-size: 18px;
        margin-right: 20px;
        @media screen and (max-width: $tablet-sm) {
          width: 30px;
          height: 30px;
          font-size: 16px;
        }
      }
    }
  }
}

.map {
  iframe {
    width: 100%;
    height: 600px;
    border: none;
    vertical-align: top;
  }
}
